export default function () {
    const handleApiErrors = (error) => {
        if (error.response) {
            return error.response.data?.message
        } else if (error.request) {
            return error.request;
        } else {
            return error.message;
        }
    };
    const handleApi       = promise => {
        return promise
        .then(data => ([data, undefined]))
        .catch(error => [undefined, handleApiErrors(error)]);
    };

    return {
        handleApi,
        handleApiErrors
    }
}